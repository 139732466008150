<template>
    <div class="page-not-found">
        <div class="pt-5">
            <section class="error-container">404</section>

            <p class="zoom-area"><b>Sorry,</b> we cannot find this page...</p>
            <p class="zoom-area"><b>But don`t worry,</b> you can find plenty of other things on our homepage.</p>

            <div class="link-container">
                <router-link class="btn more-link" to="/">Back to home</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "pageNotFound",
}
</script>