<template>
    <div class="page-not-found">
        <div class="pt-5">
            <section class="error-container">404</section>

            <p class="zoom-area"><b>Sorry,</b> we cannot find this enterprise page...</p>
            <!-- <p class="zoom-area"><b>But don`t worry,</b> you can find plenty of other things on our homepage.</p> -->
            <p class="zoom-area"><b>please</b> make sure that your domain is correct or registered.</p>


            <div class="link-container">
                <router-link class="btn more-link" to="/login">Back to home</router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    name: "pageNotFound",
  

 
}
</script>