<template>
    <div>
        <PageNotFound class="pt-lg-5"/>
    </div>
</template>
<script>
import PageNotFound from '../../components/Enterprise/page/PageNotFound.vue';

export default {
    name: "pageNotFound",
    components:{
        PageNotFound
    },
}
</script>